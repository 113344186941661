

// login profile
$("document").ready(() => {
    $("#forget_success").hide();
    $("#forget").hide();
});
$("#login .to_forget").click(() => {
    $("#login").fadeOut();
    $("#forget").delay(450).fadeIn("slow");
});

$("#forget .btn").click(() => {
    $("#forget").fadeOut();
    $("#forget_success").delay(450).fadeIn("slow");
});

$("#forget_success .btn").click(() => {
    $("#forget_success").fadeOut();
    $("#login").delay(450).fadeIn("slow");
});
  // login profile END

// 驗證
function check_captcha() {
    $.ajax({
        url: 'quote/php.php?action=captcha',
        type: 'get',
        data: {
            captcha: $('#contact_we_form').val()
        },
        beforeSend: function () { }
    }).done(
        function (result) {
            if (result.result) {
                $.post('quote/php.php?action=send_mail', {
                    name: $('#name').val(),
                    mail: $('#mail').val(),
                    theme: $('#theme').val(),
                    remarks: $('#remarks').val()

                },
                    (data) => {
                        console.log(data);
                        if (data.result) {
                            //成功處理
                            //   $('#status').html('✓ 成功送出').css('color', 'green');
                            alert('成功送出');
                            location.reload();
                        }
                        else if (data.send) {
                            //信件寄送失敗
                            //   $('#status').html('× 送出失敗：寄送失敗').css('color', 'red');
                            alert('送出失敗：寄送失敗');
                            return false;
                        }
                    }
                );
            }
            else {
                //驗證碼錯誤
                alert('送出失敗：驗證失敗');
                // $('#status').html('× 送出失敗：驗證失敗').css('color', 'red');
                return false;
            }
        }
    ).fail(
        function (error) {          //錯誤處理
            alert(error.responseText);  //列印錯誤 內容
        }
    );
};
  // 驗證 END 